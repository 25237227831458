import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import FaEnvelopeOpen from '../../../assets/faEnvelopeOpen.svg';
import FaPhoneAlt from '../../../assets/faPhoneAlt.svg';
import Flowline from '../../layout/Flowline';

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue)
  const handleChange = e => {
    const thisValue = e.target.value === 'checkbox' ? e.target.checked : e.target.value;
    setValue(thisValue)
  }
  return {
    value,
    onChange: handleChange,
  }  
}

export default function Pitch() {

  const bgData = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "shutterstock_544187893.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
      site {
        siteMetadata {
          email
          phone
        }
      }
    }
  `)

  const pitchBG = getImage(bgData.file);
  const stackedPitchBG = [`linear-gradient(rgba(47,47,47,.85),rgba(47,47,47,.85))`, pitchBG];

  // form
  const email = useFormInput('');
  const website = useFormInput('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [notification, setNotification] = useState('')

  const emailVal = email.value;
  const websiteVal = website.value;

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!executeRecaptcha) {
      return
    }

    // form field validations
    if (!email.value) {
      return setNotification(`Please provide your email address.`);
    } 

    const token = await executeRecaptcha('subscribeEsp');

    const data = JSON.stringify({
      emailVal,
      websiteVal,
      token
    })

    fetch('/api/v1/contact/subscribe-esp', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-type': 'application/json'
      },
      body: data
    })
      .then(res => res.json())
      .then(data => {
        setNotification(data.msg)
      })
    
    setNotification('Data Sent')

  }

  return (
    <BgImage Tag="section" className="pitch" image={stackedPitchBG}>
      <div className="pitch--background-wrap">
        <div className="pitch--background-wrap__frame"></div>
      </div>
      <div className="pitch--content">
        <div className="container">
          <div className="title">
            <div className="title--caption">representación legal agresiva en la que puede confiar</div>
            <div className="title--wrapper">
              <h3>¡Deje que nuestros abogados con experiencia en lesiones personales comiencen a trabajar para usted!</h3>
              <span className="flowline"></span>
            </div>
          </div>
          <div className="textbox">
            <FaEnvelopeOpen />
            <span>
              &nbsp;
              {bgData.site.siteMetadata.email}
            </span>
            <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
            <FaPhoneAlt />
            <span>
              &nbsp;
              {bgData.site.siteMetadata.phone}
            </span>
          </div>
          <Flowline />
          <div className="pitch--form">
            <div className="title--wrapper">
              <h3 className="pitch--form__title">
              Suscríbete al boletín gratuito de correo electrónico
              </h3>
              <span className="flowline"></span>
            </div>
          </div>
          <div className="pitch--subscribe-form">
            <div className="pitch--subscribe-form__item">
              <div className="pitch--subscribe-form__wrapper">
                <form onSubmit={handleSubmit}>
                  <div className="pitch--email">
                    <input type="email" name="email" id="emailSubscribeEsp" placeholder="Tu Correo Electrónico" {...email} />
                    <input type="text" name="website" id="websiteSubscribeEsp" placeholder="Your Website" {...website} />
                  </div>
                  <div className="pitch--submit">
                    <input type="submit" value="Suscríbase" />
                  </div>
                  <div className="reviewBlock">
                    {notification && <span>{notification}</span>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BgImage>
  );
}
