import React from 'react'

export default function ImageBlock({ children }) {
  return (
    <div className="imageblock">
      <div className="imageframe">
        { children }
      </div>
    </div>
  )
}
