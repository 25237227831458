import React from 'react';
import Seo from '../../../components/layout/SEO';
import Partners from '../../../components/home/Espanol/Partners';
import Pitch from '../../../components/home/Espanol/Pitch';
import PracticeAreas from '../../../components/home/Espanol/PracticeAreas';
import Layout from '../../../components/layout/LayoutInnerEsp';


export default function index() {
  return (
    <Layout>
      <Seo
        lang='es'
        title='Areas de Bufete'
        canonical='es/areas-de-bufete/'
      />
      <PracticeAreas />
      <Pitch />
      <Partners />
    </Layout>
  );
}
