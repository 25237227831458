import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import React from 'react';
import Flowline from '../../layout/Flowline';

export default function Partners() {

  const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "drew-beamer-679505-unsplash.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
      allMdx(
        filter: {frontmatter: {datum: {eq: "attorneys"}}}
        sort: {fields: frontmatter___order, order: ASC}
      ) {
        edges {
          node {
            frontmatter {
              title
              roleEsp
              hero_image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            slug
            id
          }
        }
      }
    }
  `)

  const partnersBG = getImage(data.file);
  const stackedPartnersBG = [`linear-gradient(rgba(255,255,255,.9),rgba(255,255,255,.9))`, partnersBG];

  return (
    <BgImage Tag="section" className="partners" image={stackedPartnersBG}>
      <div className="container">
        <div className="title">
          <div className="title--wrapper">
            <h3>Nuestros Abogados</h3>
            <span className="flowline"></span>
          </div>
        </div>
        <Flowline />
        <div className="staff">
          <div className="staff--wrapper">
            {data.allMdx.edges.map(({ node }) => {
              const heroImage = getImage(node.frontmatter.hero_image);

              return (
                <div className="staff__column" key={node.id}>
                  <div className="staff__item">
                    <Link className="staff__item--image-frame" to={`/about/${node.slug}`}>
                      <div className="staff__item--image">

                        <GatsbyImage image={heroImage} alt={node.frontmatter.title} />
                        <div className="staff__item--image-hover"></div>
                      </div>
                    </Link>
                    <div className="staff__item--wrapper">
                      <h3 className="staff__item--name">
                        <Link to={`/about/${node.slug}`}>
                          {node.frontmatter.title}
                        </Link>
                      </h3>
                      <div className="staff__item--title">
                        {node.frontmatter.roleEsp}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </BgImage>
  );
}
