import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import FaLongArrowAltRight from '../../../assets/faLongArrowAltRight.svg';
import Flowline from '../../layout/Flowline';
import ImageBlock from '../../elements/ImageBlock';

export default function PracticeAreas() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          tag
        }
      }
      allMdx(
        filter: {fileAbsolutePath: {glob: "**/src/data/practice-areas/*"}}
        sort: {fields: frontmatter___order, order: ASC}
      ) {
        edges {
          node {
            frontmatter {
              titleEsp
              hero_image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            slug
            id
          }
        }
      }
    }
  `)

  return (
    <section className="practicesareas">
      <div className="container">
        <div className="title">
          <span className="title--sub">
            Cuando los Resultados Importan
          </span>
          <div className="title--wrapper">
            <h3>Areas de Bufete</h3>
          </div>
        </div>
        <Flowline />
        <div className="practiceareas--list">
          {data.allMdx.edges.map(({ node }) => {
            const heroImage = getImage(node.frontmatter.hero_image);

            return (
              <div className="practiceareas--columns" key={node.id}>
                <ImageBlock>
                  <Link to={`/practice-areas/${node.slug}`}>
                    <GatsbyImage image={heroImage} alt={node.frontmatter.titleEsp} />
                  </Link>
                </ImageBlock>
                <div className="title">
                  <div className="title--wrapper">
                    <h3>{node.frontmatter.titleEsp}</h3>
                  </div>
                </div>
                <div className="button">
                  <Link to={`/practice-areas/${node.slug}`}>
                    Aprende Mas&nbsp;
                    <FaLongArrowAltRight />
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  );
}
